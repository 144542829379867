<script setup>
  const { to } = defineProps({
    to: String,
    small: Boolean,
    medium: Boolean,
    left: Boolean,
    black: Boolean,
    white: Boolean,
    disabled: Boolean,
    noAnchor: Boolean,
  });
  const target = useLinkTarget(to);
</script>

<!----------------------------------------------------------------------------->

<template>
  <button
    class="arrow-button"
    v-if="noAnchor"
    :class="{ small, medium, left, black, white, disabled }"
    :aria-label="left ? 'previous' : 'next'"
  >
    <IconArrowSmall v-if="small" /><IconArrow v-else />
  </button>
  <NuxtLink
    class="arrow-button"
    v-else
    to="javascript:;"
    :class="{ small, left, black, white, disabled }"
    :aria-label="left ? 'previous' : 'next'"
  >
    <IconArrowSmall v-if="small" />
    <IconArrowMedium v-else-if="medium" />
    <IconArrow v-else />
  </NuxtLink>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .arrow-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: px(40);
    height: px(40);

    border: px(1) solid $red;
    border-radius: 100%;

    cursor: pointer;

    @include fade('background-color, border-color', 0.15s);

    :deep(svg) {
      margin-left: px(1);
      path {
        @include fade('fill', 0.15s);
      }
    }

    @include hover {
      background-color: $red;
      @include fade('background-color, border-color', 0.05s);

      :deep(svg) {
        path {
          fill: $white;
          @include fade('fill', 0.05s);
        }
      }
    }

    &.disabled {
      pointer-events: none;
    }

    &.small {
      width: px(30);
      height: px(30);

      @include hover {
        border-color: $white;
      }
    }

    &.left {
      :deep(svg) {
        transform: rotate(180deg);
        margin-left: 0;
      }
    }

    &.black {
      border-color: $grey-3;

      :deep(path) {
        fill: $black;
      }

      &:not(.small) {
        @include hover {
          border-color: $black;

          :deep(path) {
            fill: $black;
          }
        }
      }
    }

    &.white {
      border-color: $grey-1;

      :deep(path) {
        fill: $white;
      }

      @include hover {
        border-color: $white;
        background-color: $white;

        :deep(path) {
          fill: $black;
        }
      }
    }

    @include break-small {
      // display: none;
    }
  }
</style>
