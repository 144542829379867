<template>
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.27174 4.99863L0.205078 1.93196L1.26594 0.871094L5.39348 4.99863L1.26594 9.12616L0.205078 8.06529L3.27174 4.99863Z"
      fill="black"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(6);
  }
</style>
