<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <div class="foreground"><slot /></div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .foreground {
    position: relative;
    z-index: $layer-foreground;
  }
</style>
