<template>
  <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.40859 6.49989L0.808594 1.89989L2.39989 0.308594L8.59119 6.49989L2.39989 12.6912L0.808594 11.0999L5.40859 6.49989Z"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(9);
  }
</style>
