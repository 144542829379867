<script setup>
  const {} = defineProps({ url: String, itemsPerPage: Number, type: String, white: Boolean });

  // IMPORTANT: parent component expected to provide these
  const totalItems = inject('totalItems');
  const currentPage = inject('currentPage');
  const paginate = inject('paginate');
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="pagination" :class="{ white }">
    <ShowAnimation fadeOnly>
      <vue-awesome-paginate
        type="link"
        :max-pages-shown="3"
        :link-url="url"
        :total-items="totalItems"
        :items-per-page="itemsPerPage"
        v-model="currentPage"
        :on-click="paginate"
      >
        <template v-slot:prev-button>
          <li><ArrowButton small left :black="!white" :white="white" /></li>
        </template>
        <template v-slot:next-button>
          <li><ArrowButton small :black="!white" :white="white" /></li>
        </template>
      </vue-awesome-paginate>
    </ShowAnimation>
    <ShowAnimation fadeOnly
      ><aside v-if="type">{{ totalItems }} {{ type + (totalItems !== 1 ? 's' : '') }}</aside></ShowAnimation
    >
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .pagination {
    user-select: none;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: px(66) 0 px(28);
    gap: px(18);

    &.white {
      aside {
        @include industry-demi(px(12));
        color: $grey-1;
      }

      :deep(ul.pagination-container) {
        color: $white;

        .active-page {
          border-color: $white;
        }
      }
    }

    :deep(ul.pagination-container) {
      display: flex;
      align-items: center;

      gap: px(28);

      @include industry-demi(px(12));
      color: $black;

      .paginate-buttons {
        border: none;
        cursor: pointer;

        width: px(10);

        @include fade('color, border-color', 0.92s);
        @include hover {
          color: $red;
          border-color: $red;

          @include fade('color, border-color', 0.04s);
        }

        &.back-button,
        &.next-button {
          width: auto;
        }
      }

      .next-button,
      .prev-button {
        display: none;
      }

      .active-page {
        border-bottom: px(1) solid $black;
      }
    }
  }

  aside {
    font-size: px(12);
    color: $grey-3;
  }
</style>
