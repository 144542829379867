<script setup>
  const { speed, scroll } = defineProps({ speed: { default: 1.0 }, scroll: { default: 1.0 }, noSpacer: Boolean });

  const original = ref();
  const container = ref();
  const content = ref();

  // --

  let visible = false;

  useTelescope(container, {
    visible: () => (visible = true),
    partial: () => (visible = true),
    hidden: () => (visible = false),
  });

  // --

  const scrollSpeed = inject('scrollSpeed');

  // --

  const MIN_SPEED = speed * 80.0;
  const MAX_SPEED = 120.0;

  const SCROLL_SPEED = scroll * 4.0;

  let contentWidth = 0.0;
  let velocity = 1.0;

  let prevWindowWidth = 0.0;

  onResize((windowWidth) => {
    if (windowWidth === prevWindowWidth) return;
    prevWindowWidth = windowWidth;

    content.value.innerHTML = '';

    contentWidth = original.value.getBoundingClientRect().width;
    const count = Math.ceil(container.value.getBoundingClientRect().width / contentWidth);

    position.value = 0.0; //-contentWidth * 0.7;

    for (let i = 0; i <= count; i++) {
      content.value.appendChild(original.value.children[0].cloneNode(true));
    }

    velocity = Math.min(Math.max(windowWidth * speed * 0.04, speed * MIN_SPEED), MAX_SPEED);
  });

  const position = ref(0.0);

  onTick((dt) => {
    if (!visible) return;
    position.value = (position.value - dt * (velocity + Math.abs(scrollSpeed.value * SCROLL_SPEED))) % contentWidth;
  });
</script>

<!----------------------------------------------------------------------------->

<template>
  <div ref="container" class="container">
    <span ref="content" class="content" :style="{ transform: `translate3d(${position}px, 0.0, 0.0)` }"><slot /></span>
    <span ref="original" class="original">
      <span v-if="noSpacer"><slot /></span>
      <span v-else><slot />&nbsp;</span>
    </span>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .container {
    position: relative;
    pointer-events: none;

    user-select: none;
  }

  .content,
  .original {
    white-space: nowrap;
  }

  .content {
    position: absolute;
    left: 0;
  }

  .original {
    visibility: hidden;
  }
</style>
